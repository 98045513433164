import { Box, Heading, Stack, Text } from "@chakra-ui/react"
import { navigate } from "gatsby-link"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSearchParam } from "react-use"
import AccountGate from "~/components/account/AccountGate"
import { useAuth } from "~/hooks/useAuth"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import { isBrowser } from "~/services/Utils"
import PasswordForm from "../../../src/components/account/PasswordForm"
import ErrorAlert from "../../components/ErrorAlert"

type FormData = {
  password: string
  confirmation: string
}

const ConfirmRegister = () => {
  const api = usePrinticularApi()
  const { loginSuccess } = useAuth()

  const { t } = useTranslation()
  const [error, setError] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm()
  const { handleSubmit } = methods

  const token = useSearchParam("token")
  if (isBrowser && !token) {
    navigate("/")
  }

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)

    try {
      const { password } = data
      const response = await api.registerConfirmation({
        password,
        token: token!,
      })

      loginSuccess(response, true)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  return (
    <AccountGate>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            my={{ base: 0, md: 20 }}
            spacing={5}
            mx="auto"
            maxWidth="100%"
            width="5xl"
          >
            <Box>
              <Heading as="h1" size="lg">
                {t("pages.register.confirm.Reset")}
              </Heading>
              <Text m={0} mt={3} fontSize="md">
                {t("pages.register.confirm.ResetInfo")}
              </Text>
            </Box>

            {error && <ErrorAlert data={error} />}

            <PasswordForm isLoading={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    </AccountGate>
  )
}

export default ConfirmRegister
