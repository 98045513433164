import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { ApiException } from "~/interfaces/entities/ApiException"

const ErrorAlert = ({
  data,
  displayErrorsList = true,
}: {
  data: ApiException
  displayErrorsList?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Alert status="error" borderRadius={4}>
      <AlertDescription>
        <Stack spacing={2}>
          <Flex alignItems="center">
            <AlertIcon />
            <Heading as="h3" fontSize={["sm", "md", "md", "md"]}>
              {data?.message ?? t("components.ErrorAlert.Default")}
            </Heading>
          </Flex>
          {displayErrorsList && data?.errors?.length! > 0 && (
            <Box>
              <UnorderedList
                fontSize={["xs", "xs", "xs", "sm"]}
                color="gray.600"
                ml={5}
              >
                {data?.errors?.map((error: any, index: number) => (
                  <ListItem key={index}>{error.title}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          )}
        </Stack>
      </AlertDescription>
    </Alert>
  )
}

export default ErrorAlert
